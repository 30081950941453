<template>
  <div class="outer-wrapper">
    <div class="title-wrapper">
      <span>权限管理</span>
    </div>
    <div class="inner-wrapper">
      <div class="editor-wrapper">
        <Tabs>
          <TabPane label="当前用户" icon="ios-people">
              <query-table
              :columnsFormatData="columnsFormatData"
              :askData="askData"
              :refreshData="update_count"
              main_width="750px"
            ></query-table>
          </TabPane>
        </Tabs>
      </div>
      <div class="preview-wrapper">
        <Tabs>
          <TabPane label="添加用户" icon="md-cloud-upload">
            <div class="form-wrapper">
              <Form
                ref="addUser"
                :model="newUser"
                :rules="ruleValidate"
                :label-width="150"
              >
                <FormItem label="姓名：" prop="fullName">
                  <Input v-model="newUser.fullName" placeholder="用户姓名" style="width: 260px"/>
                </FormItem>
                <FormItem label="手机号：" prop="account">
                  <Input v-model="newUser.account" placeholder="11位数字格式的手机号" style="width: 260px"/>
                </FormItem>
                <FormItem label="权限：" prop="role">
                  <Select
                    v-model="newUser.role"
                    multiple
                    style="width: 260px"
                  >
                    <Option v-for="i in roleList" :value="i.role_code" :key="i.id">{{i.role_name}}</Option>
                  </Select>
                </FormItem>
                <FormItem>
                  <Button type="primary" @click="handleSubmit('addUser')"
                    >提交</Button
                  >
                  <Button
                    @click="handleReset('addUser')"
                    style="margin-left: 8px"
                    >重置</Button
                  >
                </FormItem>
              </Form>
              <Spin fix v-show="loadingStatus"></Spin>
            </div>
          </TabPane>
          <TabPane label="上传职位列表" icon="md-cloud-upload">
            <div class="re-l form-wrapper">
              <div class="title-wrapper">
                <p>
                  请上传包含职位相关数据的excel文件：
                  <a href="/statics/templates/FiveDelayTemplate.xlsx">(模板文件下载)</a>
                </p>
              </div>
              <file-upload target="mulityByExcel" iurl="/role_alter"></file-upload>
            </div>
          </TabPane>
        </Tabs>
      </div>
      
    </div>

    <Modal v-model="scModal" width="800" :mask-closable="false" @on-cancel="modal_cancel">
      <p slot="header" class="sc-header">
        <Icon type="ios-list-box" />
        <span>编辑人员职权</span>
      </p>
      <div class="sc-content-wrapper">
        <div class="sub-content-wrapper">
          <div class="sub-content-title">人员信息</div>
          <div class="table-wrapper">
            <table>
              <tr>
                <td>姓名：</td>
                <td>{{currentStaff.full_name}}</td>
              </tr>
              <tr>
                <td>账号：</td>
                <td>{{currentStaff.account}}</td>
              </tr>
              <!-- <tr>
                <td>状态：</td>
                <td>
                  <select type="text" placeholder v-model="currentStaff.stat" style="width:200px;height:30px;">
                    <option v-for="i in statList" :value="i" :key="i">{{i}}</option>
                  </select>
                </td>
              </tr> -->
            </table>
          </div>
        </div>
        <div class="sub-content-wrapper">
          <div class="sub-content-title">职位</div>
          <div class="table-wrapper">
            <div style="color:#333;">已担任职位：</div>
            <div class="choosed-product-wrapper">
              <table style="width:100%;">
                <tbody>
                  <tr
                    v-for="(one,index) in currentStaff.role"
                    :key="index"
                    class="modal-product-choosed-tr"
                  >
                    <td style="textAlign:left;minWidth:80px;">{{one.name}}</td>
                    <td style="textAlign:left;maxWidth:120px;">{{one.code}}</td>
                    <td style="textAlign:right;minWidth:30px;">
                      <span @click="delRole(index)" class="fast-button">删除</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <table>
              <tr>
                <td>权限：</td>
                <td>
                  <select type="text" placeholder v-model="choosedRole" style="width:200px;height:30px;">
                    <option v-for="i in roleList" :value="i" :key="i.id">{{i.role_name}}</option>
                  </select>
                </td>
              </tr>
              <tr>
                <td style="verticalAlign: text-top;"></td>
                <td>
                  <Button @click="addRole">添加该职位</Button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div slot="footer">
        <Button size="large" @click="modal_save">确定</Button>
        <Button size="large" @click="modal_cancel">取消</Button>
      </div>
    </Modal>
  </div>
</template>
<script>
import fileUpload from "@/components/Common/FileUpload.vue";
import queryTable from "@/components/Common/QueryTable.vue";

let userToken = localStorage.getItem("current_user_token");

function newUserFac(){
  return {
    fullName:'',
    account:'',
    role:[]
  };
}

export default {
  name: "roleManage",
  components: {
    queryTable,
    fileUpload
  },
  props: {},
  data() {
    return {
        askData: "roleManage",
        update_count:0,
        columnsFormatData: [
          {
            title: "名称",
            key: "role_name",
            width: 80,
            align: "center"
          },

          {
            title: "代码",
            key: "role_code",
            align: "center",
            width: 115
          },
          {
            title: "权限",
            key: "authority",
            align: "center",
          },
          // {
          //   title: "操作",
          //   align: "center",
          //   width: 130,
          //   render: (h, params) => {
          //     return h("div", [
          //       h(
          //         "Button",
          //         {
          //           props: {
          //             size: "small"
          //           },
          //           style: { color: "green" },
          //           on: {
          //             click: () => {
          //               this.staffEdit(params.row);
          //             }
          //           }
          //         },
          //         "编辑"
          //       ),
          //       h(
          //         "Button",
          //         {
          //           props: {
          //             size: "small"
          //           },
          //           style: { color: "red",marginLeft:'5px' },
          //           on: {
          //             click: () => {
          //               this.confirmBox(params.row);
          //             }
          //           }
          //         },
          //         "删除"
          //       ),
                
          //     ]);
          //   }
          // }
        ],
      loadingStatus: false,
      atimated: false,
      currentStaff: {
        full_name: "",
        account: "",
        stat: "",
        role: ['',''],
      },
      role: {
        name: "",
        code: "",
        barRights: [{
        value: "",
        index: 1,
        status: 1,
        remark:''
      }],
        dataRights: [{
        value: "",
        index: 1,
        status: 1
      }]
      },
      roleList: [],
      statList:['未审核','已审核','已离职'],
      choosedRole: {},
      newUser:newUserFac(),
      ruleValidate: {
        fullName: [
          {
            required: true,
            message: "姓名不能为空",
            trigger: "blur"
          },
          { type: 'string', max: 10, message: '姓名最长不超过10字符', trigger: 'change' }
        ],
        account: [
          {
            required: true,
            message: "手机号不能为空",
            trigger: "blur"
          },
          { type: 'string', max: 11,min:11, message: '手机号格式不正确', trigger: 'change' }
        ],
        role:[
           { required: true, type: 'array', min: 1, message: '请至少选择一个权限', trigger: 'change' },
        ]
      },
      index: {
        dataRights: 1,
        barRights: 1
      },
      scModal: false
    };
  },
  watch: {},
  computed: {
  },
  created() {
    this.get_option_data();
  },
  methods: {
    get_option_data() {
      this.$axios
        .post(this.$url + "/option", {
          userToken: userToken,
          ask_data: "roleList"
        })
        .then(
          response => {
            this.roleList = response.data.res_record;
          }
        );
    },
    handleReset(name) {
      this.$refs[name].resetFields();
    },
    handleAdd(value) {
      this.index[value]++;
      this.role[value].push({
        value: "",
        index: this.index[value],
        status: 1
      });
    },
    handleRemove(value,i) {
      this.role[value].splice(i, 1);
    },
    staffEdit(value) {
      this.scModal = true;
      this.currentStaff = JSON.parse(JSON.stringify(value));
      if(!this.currentStaff.role){
        this.currentStaff.role = [];
      }else{
        this.currentStaff.role = JSON.parse(this.currentStaff.role);
      }
    },
    addRole(){
      let tmpRole = {name:this.choosedRole.role_name,code:this.choosedRole.role_code,id:this.choosedRole.id};
      this.currentStaff.role.push(tmpRole);
    },
    delRole(index) {
      this.currentStaff.role.splice(index, 1);
    },
    modal_save(){
      this.$axios
        .post(this.$url + "/account_alter", {
          askData:'roleAlter',
          userToken: userToken,
          newData:this.currentStaff
        })
        .then(response => {
          if (response.data.stat === "ok") {
            this.$Message.success("修改成功！");
            this.update_count++;
          } else {
            this.$Message.warning(response.data.error_info);
          }
          this.modal_cancel();
        }).catch(error=>{
          this.$Message.error('网络连接有误！'+error);
        });

    },
    modal_cancel(){
      this.scModal = false;

    },

    handleSubmit(name) {
      this.$refs[name].validate(valid => {
        if (valid) {
          let form = new FormData();
          form.append("userToken", userToken);
          form.append("askData", 'addUser');
          form.append("newUser", JSON.stringify(this.newUser));
          this.loadingStatus = true;
          let config = {
            headers: { "Content-Type": "multipart/form-data" }
          };
          this.$axios
            .post(this.$url + "/account_alter", form, config)
            .then(response => {
              this.loadingStatus = false;
              if (response.data.stat === "ok") {
                this.$Message.success("用户添加成功！");
                // this.update_count++;
                // this.handleReset(name);
                location.reload();
              } else {
                this.$Message.error(response.data.error_info);
              }
            })
            .catch(error => {
              this.loadingStatus = false;
              this.$Message.error(error + "未知错误！");
            });
        } else {
          this.$Message.error("填入信息错误!");
        }
      });
    },
    confirmBox(value) {
      this.$Modal.confirm({
        title: "确认删除该用户：",
        content:
          "<p>" +
          "用户名：" +
          value.full_name +          
          "</p>"+
          "<p>" +
          "账号：" +
          value.account +          
          "</p>",
        onOk: () => {
          this.delUser(value.account);
        },
        onCancel: () => {}
      });
    },
    delUser(account) {
      this.$axios
        .post(this.$url + "/account_alter", {
          userToken: userToken,
          askData: "delUser",
          account: account
        })
        .then(response => {
          if (response.data.stat === "ok") {
            this.$Message.success("删除成功！");
            this.update_count++;
          }
        })
        .catch(error => {
          this.$Message.warning(error + "网络错误！操作失败。");
        });
    },
  }
};
</script>
<style scoped>
.inner-wrapper {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  min-height:700px;
}

.title-wrapper {
  text-align: center;
  font-size: 20px;
  padding: 16px;
}

.editor-wrapper,
.preview-wrapper {
  margin: 0px 20px 0px;
  width: 750px;
  font-size: 14px;
  text-align: center;
  padding: 10px 0;
}

.form-wrapper {
  text-align: left;
  border: 1px solid #eee;
  padding: 80px 120px 0px 50px;
  min-height:600px;
  position: relative;
}

.sc-content-wrapper {
  padding: 0 50px;
  color: #333;
  font-size: 14px;
}
.choosed-product-wrapper {
  min-height: 100px;
  padding: 10px 20px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 1px #eee;
  margin: 20px 0;
}

.sc-content-wrapper td:first-child {
  text-align: right;
  padding-right: 10px;
}

.sc-content-wrapper td {
  height: 45px;
  color: #777;
}

.sub-content-wrapper {
  margin: 10px 0;
  border: 1px solid #eee;
  position: relative;
}

.sub-content-title {
  position: absolute;
  left: 0;
  overflow: hidden;
  height: 100%;
  writing-mode: vertical-lr;
  text-align: center;
  background: #f5f5f5;
  letter-spacing: 10px;
  text-indent: 10px;
  padding: 12px 10px;
}

.table-wrapper {
  padding: 22.5px 20px;
  margin-left: 50px;
}

.fast-button {
  cursor: pointer;
}

input::-webkit-input-placeholder {
  color: #ccc;
  text-align: center;
}

input:-ms-input-placeholder {
  color: #ccc;
  text-align: center;
}

input::-moz-placeholder {
  color: #ccc;
  text-align: center;
}
tr.modal-product-choosed-tr {
  border-bottom: 1px solid #ddd;
}

tr.modal-product-choosed-tr:last-child {
  border:none;
}
</style>